define("whats-app/controllers/whatsapp", ["exports", "jquery", "whats-app/http/methods", "sweetalert2"], function (_exports, _jquery, _methods, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-jquery */

  /* eslint-disable ember/no-observers */

  /* global async */
  function urltoFile(url, filename, mimeType) {
    return fetch(url).then(function (res) {
      return res.arrayBuffer();
    }).then(function (buf) {
      return new File([buf], filename, {
        type: mimeType
      });
    });
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {
      type: mime
    });
  }

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]); // separate out the mime component

    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // write the bytes of the string to an ArrayBuffer

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    } // write the ArrayBuffer to a blob, and you're done


    var bb = new Blob([ab], {
      type: mimeString
    });
    return bb;
  }

  function extractFiles(medias) {
    console.log("CHEGOU AO extractFiles", medias); //

    let results = []; //for(let media in medias){

    for (let media of medias) {
      console.log("media no extractFiles: ", media);
      if (typeof media.reader !== 'string') break;
      var mime = media.reader.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,(.*)/);
      console.log("mime", mime); //

      media.result = {
        type: mime[1],
        data: mime[2]
      }; //transforma em objeto

      console.log("media.reader: ", media.reader); //

      console.log("media.result: ", media.result); //

      console.log("media.filename", media.filename);
      results.push({
        filename: media.filename,
        ...media.result
      });
    }

    console.log("_results: ", results);
    console.log("fim do extractFiles");
    return results;
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  String.prototype.ltrim = function () {
    return this.replace(/^\s+/, "");
  };

  String.prototype.rtrim = function () {
    return this.replace(/\s+$/, "");
  };

  function blink(selector) {
    (0, _jquery.default)(selector).fadeOut('fast', function () {
      (0, _jquery.default)(this).fadeIn('slow', function () {});
    });
  }

  var _default = Ember.Controller.extend({
    socket: Ember.inject.service(),
    session: Ember.inject.service(),
    //envio de mensagem
    messageConversation: '',
    stateWasSend: false,
    image: '',
    message: '',
    quantitySent: 0,
    intervalSent: 3000,
    filter: '',
    filtered: false,
    countChats: 0,
    readers2: [],
    media2: {},
    activeConversation: '',
    //importação de numeros avulso
    numberPhones: "",
    //mensagem de saudação
    greetingMessage: null,
    greetingTriggers: null,
    //efeito de 'loading...' na interface
    showLoading: false,
    allMsgsLoaded: false,
    allChatLoaded: false,
    showLoadingDownloadMedia: false,
    loadingConversation: false,
    loadingMoreMessages: false,
    toggleChecked: true,
    toggleCheckedPlaySound: true,
    messages_grouped: null,
    forwardChats: null,
    mfowardid: null,
    categorys: [],
    connectedObserver: Ember.observer('socket.{isConnected,isReady}', function () {
      Ember.set(this, 'isConnected', this.socket.isConnected || this.socket.isReady);
    }),
    ackObserver: Ember.observer('socket.message_ack', function () {
      try {
        const update_ack = this.socket.message_ack;

        if (update_ack) {
          const chats = this.chats;

          if (chats && chats.length > 0 && update_ack) {
            const index_chat = chats.filter(c => c.message !== undefined).findIndex(c => c.message.mid == update_ack.message_id);
            if (index_chat !== -1) Ember.set(chats[index_chat].message, 'status', update_ack.ack);
            const current_cid = this.contactId;

            if (current_cid && update_ack.chat_id == current_cid) {
              let current = this.current_conversation;

              if (current) {
                const index_message = current.findIndex(m => m.mid == update_ack.message_id);
                if (index_message !== -1) Ember.set(current[index_message], 'status', update_ack.ack);
                this.send('formatMessages');
              }
            }
          }
        }
      } catch (error) {
        console.log('Erro no socket de message_ack: ' + error);
      }
    }),
    mediaUploaded: Ember.observer('socket.media_uploaded', function () {
      try {
        const mount_chat = this.socket.media_uploaded;
        const cat = mount_chat.category;
        let permited = false;
        let accessAllCategorys = false;
        let correctlyCategory = cat != null && this.attendant_categorys_ids.includes(cat.toString());
        accessAllCategorys = this.accessAllCategorys == 'true';
        permited = accessAllCategorys || correctlyCategory;

        if (permited === true) {
          this.send('applyMessage', mount_chat);
          Ember.set(this, 'showLoadingDownloadMedia', false);
          Ember.set(this, 'idMediaDownload', null);
        }
      } catch (error) {
        console.log('Erro no socket de message uploaded: ' + error);
      }
    }),
    messageObserver: Ember.observer('socket.message_received', function () {
      try {
        const mount_chat = this.socket.message_received;
        const cat = mount_chat.category;
        let permited = false;
        let accessAllCategorys = false;
        let correctlyCategory = cat != undefined && this.attendant_categorys_ids.includes(cat.toString());
        accessAllCategorys = this.accessAllCategorys == 'true';
        permited = accessAllCategorys || correctlyCategory;

        if (permited == true) {
          this.send('applyMessage', mount_chat);

          if (mount_chat.message.me && mount_chat.message.hasMedia) {
            Ember.set(this, 'showLoadingDownloadMedia', true);
            Ember.set(this, 'idMediaDownload', mount_chat.message.mid);
          }
        } //alert(correctlyCategory);
        //alert(cat);
        //alert(permited);

      } catch (error) {
        console.log('Erro no socket de message received: ' + error);
      }
    }),
    unreadObserver: Ember.observer('socket.unread_chat', function () {
      try {
        const mount_chat = this.socket.unread_chat;

        if (mount_chat) {
          mount_chat.unread_count = mount_chat.valueUnread;
          const chats = this.chats;
          const index = chats.findIndex(c => c.cid == mount_chat.chat_id);

          if (index != -1) {
            Ember.set(chats[index], 'unread_count', mount_chat.valueUnread);
            this.send('applyUnreadCount', chats.filter(c => c.unread_count > 0 || c.unread_count == -1).length);
          }
        }
      } catch (error) {
        console.log('Erro no socket de message received: ' + error);
      }
    }),
    categoryUpdateObserver: Ember.observer('socket.category_update', function () {
      try {
        const mount_chat = this.socket.category_update;

        if (mount_chat) {
          let permited = false;
          let accessAllCategorys = false;
          let correctlyCategory = mount_chat.category_id != undefined && this.attendant_categorys_ids.includes(mount_chat.category_id.toString());
          accessAllCategorys = this.accessAllCategorys == 'true';
          permited = accessAllCategorys || correctlyCategory;

          if (permited == false) {
            const chats = this.chats;
            const index = chats.findIndex(c => c.cid == mount_chat.chat_id);

            if (index != -1) {
              let new_chats = Ember.copy(chats, true);
              new_chats.splice(index, 1);
              Ember.set(this, 'chats', new_chats);
              this.send('applyUnreadCount', new_chats.filter(c => c.unread_count > 0 || c.unread_count == -1).length);

              if (this.activeChat == mount_chat.chat_id) {
                this.send('removeLoadConv');
              }
            }
          }
        }
      } catch (error) {
        console.log('Erro no socket de message received: ' + error);
      }
    }),
    doneObserver: Ember.observer('socket.done_chat', function () {
      try {
        const mount_chat = this.socket.done_chat;

        if (mount_chat) {
          if (this.accessAllCategorys == 'false') {
            const chats = this.chats;
            const index = chats.findIndex(c => c.cid == mount_chat.chat_id);

            if (index != -1) {
              let new_chats = Ember.copy(chats, true);
              new_chats.splice(index, 1);
              Ember.set(this, 'chats', new_chats);
              this.send('applyUnreadCount', new_chats.filter(c => c.unread_count > 0 || c.unread_count == -1).length);

              if (this.activeChat == mount_chat.chat_id) {
                this.send('removeLoadConv');
              }
            }
          }
        }
      } catch (error) {
        console.log('Erro no socket de message received: ' + error);
      }
    }),
    attendantObserver: Ember.observer('socket.attendant_update', function () {
      try {
        const mount_chat = this.socket.attendant_update;
        const cat = mount_chat.category;
        let permited = false;
        let accessAllCategorys = false;
        let correctlyCategory = cat != null && this.attendant_categorys_ids.includes(cat.toString());
        accessAllCategorys = this.accessAllCategorys == 'true';
        permited = accessAllCategorys || correctlyCategory;

        if (permited === true) {
          mount_chat.attendant = mount_chat.attendant;
          const chats = this.chats;
          const index = chats.findIndex(c => c.cid == mount_chat.chat_id);
          Ember.set(chats[index], 'attendant', mount_chat.attendant); //this.send('applyUnreadCount', chats.filter(c=>c.unread_count > 0 || c.unread_count == -1).length);
        }
      } catch (error) {
        console.log('Erro no socket de message received: ' + error);
      }
    }),
    revokeEveryoneObserver: Ember.observer('socket.message_revoke_everyone', function () {
      try {
        const message_revoke = this.socket.message_revoke_everyone;
        const cat = message_revoke.category;
        let permited = false;
        let accessAllCategorys = false;
        let correctlyCategory = cat != null && this.attendant_categorys_ids.includes(cat.toString());
        accessAllCategorys = this.accessAllCategorys == 'true';
        permited = accessAllCategorys || correctlyCategory;

        if (permited === true) {
          const chats = this.chats;

          if (chats && chats.length > 0 && message_revoke) {
            const index_chat = chats.filter(c => c.message !== undefined).findIndex(c => c.message.timestamp == message_revoke.timestamp && c.message.pure_text == message_revoke.pure_text);
            if (index_chat !== -1) Ember.set(chats[index_chat].message, 'type', 'revoked');
            const current_cid = this.contactId;

            if (current_cid && message_revoke.chat_id == current_cid) {
              let current = this.current_conversation;

              if (current) {
                const index_message = current.findIndex(m => m.timestamp == message_revoke.timestamp && m.pure_text == message_revoke.pure_text);
                if (index_message !== -1) Ember.set(current[index_message], 'type', 'revoked');
                this.send('formatMessages');
              }
            }
          }
        }
      } catch (error) {
        console.log('Erro no socket de message_revoke_everyone: ' + error);
      }
    }),
    typingObserver: Ember.observer('messageConversation', function () {
      const message = this.messageConversation;
      const stateWasSend = this.stateWasSend;
      const contact = this.contactId;

      if (!stateWasSend || stateWasSend && !message) {
        _methods.default.post('send-state', {
          message: message,
          contact: contact,
          typing: true
        }).then(res => {
          Ember.set(this, 'stateWasSend', true);
        }).catch(err => {
          console.log('Erro ao enviar mensagem => ' + err.responseJSON.message);
        });
      }
    }),
    groupMessages: Ember.observer('current_conversation', function () {
      this.send('formatMessages');
    }),
    emojiMsgObserver: Ember.observer('emojiMsg', function () {
      Ember.set(this, 'messageConversation', this.messageConversation + this.emojiMsg);
    }),
    contactsObserver: Ember.observer('message', 'image', 'intervalSent', 'contacts.@each.{selected,sent,sentTemp}', function () {
      Ember.run.debounce(this, this.contactsSaveStorage, 500);
    }),
    contactsSaveStorage: function () {
      localStorage.setItem("__whatsappMessenger", JSON.stringify({
        token: localStorage.getItem('__whats_id'),
        image: this.image,
        message: this.message,
        contacts: this.contacts,
        intervalSent: this.intervalSent
      }));
      this.controller.set('toggleChecked', true);
      this.controller.set('toggleCheckedPlaySound', true);
    },
    actions: {
      scrollToMessage: function (id) {
        var element = document.getElementById(id);

        if (element != undefined && element != null) {
          const intersectionObserver = new IntersectionObserver(entries => {
            let [entry] = entries;

            if (entry.isIntersecting) {
              setTimeout(() => {
                blink(element);
                element = undefined;
              }, 100);
            }
          });
          intersectionObserver.observe(element);
          element.scrollIntoView({
            block: 'start',
            behavior: 'smooth'
          });
        } else if (element == null) {
          alert('Essa mensagem ainda não foi carregada na sua conversa. Carregue mais mensagens para que possamos navegar até a mesma.');
        }
      },
      toggleCheckBox: function () {
        Ember.set(this, 'toggleChecked', !this.toggleChecked);
      },
      toggleCheckBoxPlaySound: function () {
        Ember.set(this, 'toggleCheckedPlaySound', !this.toggleCheckedPlaySound);
      },
      sendNewMessage: function () {
        //set(this, 'showLoading', true);
        var pais = 55;
        var ddd = this.dddNew;
        var number = this.numberNew;
        var cat = this.categoryNM;
        var DDDandNumber = pais + ddd + number;

        if (this.new_message != null) {
          var messageNew = this.new_message.ltrim();
          messageNew = messageNew.rtrim();
          if (messageNew != '' && this.toggleChecked === true) messageNew = '*' + localStorage.getItem('__whats_username') + '*\n' + messageNew;else messageNew = messageNew;
        }

        _methods.default.post('send-new', {
          message: messageNew,
          phone: DDDandNumber,
          categoryNM: cat
        }).then(res => {
          Ember.set(this, 'dddNew', null);
          Ember.set(this, 'numberNew', null);
          Ember.set(this, 'new_message', null);
          (0, _jquery.default)(".overlay, .menuWrap").fadeOut(180);
          (0, _jquery.default)(".menu").animate({
            opacity: '0',
            left: '-320px'
          }, 180);
          (0, _jquery.default)(".config").animate({
            opacity: '0',
            right: '-200vw'
          }, 180);
          (0, _jquery.default)(".newMessage").animate({
            opacity: '0',
            right: '-200vw'
          }, 180);
        }).catch(err => {
          const message = err.responseJSON.message;
          if (message && message.includes("Mensagem de texto obrigatória.")) alert(message);else alert('Erro ao enviar a mensagem! Verifique se preencheu os campos corretamente.'); //console.log('Erro ao enviar nova mensagem => ' + err.responseJSON.message);
        });
      },
      showWebVersion: function () {
        alert('Rodando WhatsApp Web: V' + (this.socket.wWebVersion || 'UNKNOWN'));
      },
      selectAll: function (value) {
        this.contactsUser.forEach(contact => Ember.set(contact, 'selected', value));
      },
      selectAllGroup: function (value) {
        this.contactsGroup.forEach(contact => Ember.set(contact, 'selected', value));
      },
      selectAllSelected: function (value) {
        this.contactsSelected.forEach(contact => Ember.set(contact, 'selected', value));
      },
      applyLoadConv: function (res, old, useCount) {
        //console.log(res.favoriteMessages)
        this.send('resetFile');
        let _new = res.full;

        if (useCount) {
          if (res.fecth > old.length) {
            const final = _new.concat(old);

            Ember.set(this, 'current_conversation', final);
          } else Ember.set(this, 'allMsgsLoaded', true);
        } else Ember.set(this, 'current_conversation', _new);

        const chats = this.chats;
        const index = chats.findIndex(c => c.cid == res.contactId);
        Ember.set(chats[index], 'unread_count', 0);
        this.send('applyUnreadCount', chats.filter(c => c.unread_count > 0 || c.unread_count == -1).length); //set(chats[index], 'attendant', res.attendant);

        if (this.current_conversation.length === 0) Ember.set(this, 'conversationEmpty', true);
        if (chats[index].isReadOnly) Ember.set(this, 'isReadOnly', true);
        if (chats[index].muted) Ember.set(this, 'isMuted', true);
        if (chats[index].pinned) Ember.set(this, 'isPinned', true);
        if (chats[index].isGroup) Ember.set(this, 'isGroup', true);
        Ember.set(this, 'contact-chat', res.contact);
        Ember.set(this, 'contactId', res.contactId);
        Ember.set(this, 'url_conversation', res.url);
        Ember.set(this, 'activeChat', res.contactId);
      },
      removeLoadConv: function () {
        //console.log(res.favoriteMessages)
        this.send('resetFile');
        Ember.set(this, 'current_conversation', null);
        Ember.set(this, 'contact-chat', null);
        Ember.set(this, 'contactId', null);
        Ember.set(this, 'url_conversation', null);
        Ember.set(this, 'activeChat', null);
      },
      applyMessage: function (mount_chat) {
        let chats = this.chats;

        if (mount_chat) {
          const current_cid = this.contactId;
          const index = chats.findIndex(c => c.cid == mount_chat.cid);

          if (current_cid && mount_chat.cid == current_cid) {
            mount_chat.unread_count = 0;
            let current = this.current_conversation;

            if (current && !current.some(m => m.mid == mount_chat.message.mid)) {
              current.replace(current.length, 0, [mount_chat.message]);
              this.send('formatMessages');
            }

            var elementoConvHist = document.getElementById("convHist");

            if (elementoConvHist.scrollHeight - elementoConvHist.scrollTop <= 1094) {
              (0, _jquery.default)(".convHistory").stop().animate({
                scrollTop: 9999999999999999999999999999999
              });
            }
          } else if (!mount_chat.muted && !mount_chat.message.me && this.toggleCheckedPlaySound === true) {
            if (document.getElementById('audioMsg')) document.getElementById('audioMsg').play();
          }

          if (index !== -1) chats.replace(index, 1);
          chats.replace(0, 0, [mount_chat]);
          Ember.set(this, 'countChats', this.chats);
          this.send('applyUnreadCount', chats.filter(c => c.unread_count > 0 || c.unread_count == -1).length);
        }
      },
      formatMessages: function () {
        const messages = this.current_conversation;

        if (messages) {
          let grouped_messages = [];
          let group = messages.reduce((r, a) => {
            r[a.date] = [...(r[a.date] || []), a];
            return r;
          }, {});

          for (let date in group) {
            let messagesDay = {
              date: "",
              messages: []
            };
            messagesDay.date = date;
            let msgs = group[date];

            for (let i = 0; i < msgs.length; i++) messagesDay.messages.push(msgs[i]);

            grouped_messages.push(messagesDay);
          }

          Ember.set(this, 'grouped_messages', grouped_messages);
        }
      },
      applyUnreadCount: function (sum) {
        Ember.set(this, 'sum_unread', sum);
        if (sum > 0) (0, _jquery.default)(document).attr('title', '(' + sum + ') OLI Zap');else (0, _jquery.default)(document).attr('title', 'OLI Zap');
      },
      resetFile: function () {
        Ember.set(this, 'medias', null);
        Ember.set(this, 'pathFile', null);
      },
      readFile: function (event) {
        let readers = [];
        let size = 0;

        for (let file of event.target.files) {
          size += Math.round(file.size / 1024);
          let reader = new FileReader();
          let media = {};

          reader.onload = () => {
            media.reader = reader.result;
            media.filename = file.name;
          };

          const valids = ['image', 'text', 'application', 'video', 'audio', 'ogg'];

          if (valids.some(v => file.type.includes(v))) {
            reader.readAsDataURL(file); //console.log(file);
          } else {
            alert("Formato de arquivo invalido");
            this.send('resetFile');
          } //console.log(media)


          readers.push(media);
        }

        if (size < 40000) Ember.set(this, 'medias', readers);else {
          alert('O tamanho máximo permitido para upload é 40mb.');
          Ember.set(this, 'medias', null);
          Ember.set(this, 'pathFile', null);
        }
      },
      ///////////
      //////////
      markReply: function (id, contact, pure_text) {
        this.send('removeReply');
        var divConvHistory = document.getElementById("convHist");
        if (divConvHistory != null) divConvHistory.style.height = 'calc(100vh - 224px)';
        Ember.set(this, 'replyId', id);

        if (contact == '') {
          contact = 'Você';
        }

        Ember.set(this, 'replyContact', contact);
        Ember.set(this, 'replyPureText', pure_text);
      },
      markReplyMedia: function (id, contact, pure_text, typeMedia, media) {
        this.send('removeReply');
        var divConvHistory = document.getElementById("convHist");
        if (divConvHistory != null) divConvHistory.style.height = 'calc(100vh - 224px)';
        Ember.set(this, 'replyId', id);

        if (contact == '') {
          contact = 'Você';
        }

        if (typeMedia == 'image') {
          Ember.set(this, 'replyMediaType', typeMedia);
          Ember.set(this, 'replyMediaImage', media);
        }

        Ember.set(this, 'replyContact', contact);
        Ember.set(this, 'replyPureText', pure_text);
      },
      removeReply: function () {
        var divConvHistory = document.getElementById('convHist');
        if (divConvHistory != null) divConvHistory.style.height = 'calc(100vh - 140px)'; //console.log(document.getElementById('convHist'));

        Ember.set(this, 'replyId', null);
        Ember.set(this, 'replyContact', null);
        Ember.set(this, 'replyPureText', null);
        Ember.set(this, 'replyMediaType', null);
        Ember.set(this, 'replyMediaImage', null);
      },
      clearConversation: function () {
        Ember.set(this, 'isReadOnly', false);
        Ember.set(this, 'isMuted', false);
        Ember.set(this, 'isPinned', false);
        Ember.set(this, 'isGroup', false);
        Ember.set(this, 'allMsgsLoaded', false);
        Ember.set(this, 'conversationEmpty', false);
      },
      loadContacts: function () {
        Ember.set(this, 'showLoading', true);

        _methods.default.get('contacts').then(res => {
          res.contacts.forEach(c => c.sent = false);
          res.contacts.forEach(c => c.selected = false);
          Ember.set(this, 'contacts', res.contacts.sort());
        }).catch(error => {
          console.log('Erro ao obter Contatos => ' + error.responseJSON.message);
        }).then(() => {
          Ember.set(this, 'showLoading', false);
        });
      },
      loadFullConversation: function (id, useCount) {
        let old = this.current_conversation;
        const count = old ? old.length : 0;
        Ember.set(this, 'loadingMoreMessages', true);
        this.send('clearConversation');

        _methods.default.post('full-chat', {
          id: id,
          num: useCount ? count : 0,
          session: localStorage.getItem('__whats_session')
        }).then(res => {
          this.send('applyLoadConv', res, old, useCount);
        }).catch(err => {
          const message = err.responseJSON.message;
          if (message && message.includes("A conversa selecionada não existe.")) alert(message);
          console.log('Erro ao abrir abrir Conversa => ' + err.responseJSON.message);
        }).then(() => {
          Ember.set(this, 'loadingMoreMessages', false);
        });
      },
      loadConversation: function (id, useCount) {
        this.send('removeReply');
        Ember.set(this, 'activeConversation', id);
        let old = this.current_conversation;
        const count = old ? old.length : 0;
        Ember.set(this, 'loadingConversation', true);
        this.send('clearConversation');

        _methods.default.post('full-chat', {
          id: id,
          num: useCount ? count : 0,
          session: localStorage.getItem('__whats_session')
        }).then(res => {
          this.send('applyLoadConv', res, old, useCount);
          Ember.set(this, 'loadingConversation', false);
        }).catch(err => {
          const message = err.responseJSON.message;
          if (message && message.includes("A conversa selecionada não existe.")) alert(message);
          console.log('Erro ao abrir abrir Conversa => ' + err.responseJSON.message);
        });
      },
      addContact: function (id) {
        var name = this.nameNewContact;
        var chatID = id;
        var description = this.descriptionNewContact;

        _methods.default.post('contacts', {
          name: name,
          chatID: chatID,
          description: description
        }).then(res => {
          Ember.set(this, 'nameNewContact', null);
          Ember.set(this, 'descriptionNewContact', null);
          Ember.set(this, 'contact-chat', name + ' - ' + this.contactId.replace("@c.us", ""));
          const chats = this.chats;
          const index = chats.findIndex(c => c.cid == this.contactId);

          if (index != -1) {
            Ember.set(chats[index], 'name', name);
          }

          (0, _jquery.default)(".overlay, .menuWrap").fadeOut(180);
          (0, _jquery.default)(".menu").animate({
            opacity: '0',
            left: '-320px'
          }, 180);
          (0, _jquery.default)(".config").animate({
            opacity: '0',
            right: '-200vw'
          }, 180);
          (0, _jquery.default)(".newContact").animate({
            opacity: '0',
            right: '-200vw'
          }, 180);
          this.send('clientInfo');
        }).catch(err => {
          const message = err.responseJSON.message;
          if (message) alert(message);else alert('Erro ao adicionar contato! Verifique se preencheu os campos corretamente.'); //console.log('Erro ao enviar nova mensagem => ' + err.responseJSON.message);
        });
      },
      forwardMessage: async function () {
        var el = document.getElementById('encaminharSelects');
        var tops = el.getElementsByTagName('input');

        for (var i = 0, len = tops.length; i < len; i++) {
          if (tops[i].checked === true) {
            _methods.default.post('foward-message', {
              message_id: this.mfowardid,
              contact: this.contactId,
              chatTo: tops[i].id,
              count_msgs: this.current_conversation.length
            }).then(res => {}).catch(error => {
              console.log('Erro ao encaminhar mensagem => ' + error.responseJSON.message);
            });

            await sleep(1000);
          }
        }

        Ember.set(this, 'mfowardid', null);
        Ember.set(this, 'forwardChats', null);
        (0, _jquery.default)(".overlay, .menuWrap").fadeOut(180);
        (0, _jquery.default)(".menu").animate({
          opacity: '0',
          left: '-320px'
        }, 180);
        (0, _jquery.default)(".config").animate({
          opacity: '0',
          right: '-200vw'
        }, 180);
        (0, _jquery.default)(".forwardMessage").animate({
          opacity: '0',
          right: '-200vw'
        }, 180);
      },
      loadChats: function () {
        const count = this.countChats;
        const filtered = this.filtered;
        Ember.set(this, 'showLoadingChats', true);

        _methods.default.post('chats', {
          count: filtered ? 0 : count,
          accessAllCategorys: this.accessAllCategorys,
          categorys_ids: this.attendant_categorys_ids
        }).then(res => {
          //console.log(res.categorys)
          Ember.set(this, 'filtered', false);
          Ember.set(this, 'countChats', res.count);
          let _new = res.chats;

          if (count > 0 && !filtered) {
            let old = this.chats;
            const final = old.concat(_new);
            Ember.set(this, 'chats', final);
            if (final.length === res.max) Ember.set(this, 'allChatLoaded', true);
          } else {
            Ember.set(this, 'chats', res.chats);
          }

          this.send('applyUnreadCount', res.chats.filter(c => c.unread_count > 0 || c.unread_count == -1).length);
        }).catch(error => {
          console.log('Erro ao carregar Conversas => ' + error.responseJSON.message);
        }).then(() => {
          Ember.set(this, 'showLoadingChats', false);
        });
      },
      send: function () {
        if (this.messageConversation != null) {
          var messageTxt = this.messageConversation.ltrim();
          messageTxt = messageTxt.rtrim();
          if (messageTxt != '' && this.toggleChecked === true) messageTxt = '*' + localStorage.getItem('__whats_username') + '*\n' + messageTxt;else messageTxt = messageTxt;
        }

        const message = messageTxt;
        Ember.set(this, 'messageConversation', '');
        Ember.set(this, 'showLoading', true); //console.log(this.medias);
        // CONFIGURAÇÃO DE ONDE VAI SER ENVIADO
        //! BACKUP INICIO 

        /*
        
        let bbbbbb = this.medias; //FORMA TRADICIONAL
        let aaaaaa = window.estruturaResults; //FORMA DO ARRASTA E SOLTA
            console.log("chegando no ternario",aaaaaa) //se mnsg = null
        
        //const medias = aaaaaa ? extractFiles(aaaaaa) : null;
        //const medias = aaaaaa; // CONSEGUIMOS ENVIAR O window.estruturaResults ASSIM
        const medias = aaaaaa.length > 0 ? aaaaaa : null; 
          */
        //!BACKUP FIM
        //-------------FAZER FUNCIONAR OS DOIS---------------------
        //mudar a atribuição em media para conseguir fazer funcionar os dois
        //const medias = aaaaaa ? extractFiles(aaaaaa) : null;
        //const medias = bbbbbb ? extractFiles(bbbbbb) : null;

        let bbbbbb = this.medias; //FORMA TRADICIONAL

        let aaaaaa = window.estruturaResults; //FORMA DO ARRASTA E SOLTA
        // var medias = aaaaaa.length > 0 ? aaaaaa : null; 

        console.log("chegando no ternario:aaaaaa", aaaaaa); //se mnsg = null

        console.log("chegando no ternario:bbbbbb", bbbbbb); //se mnsg = null

        if (bbbbbb !== null) {
          //var medias = bbbbbb;
          var medias = bbbbbb ? extractFiles(bbbbbb) : null;
          console.log("valor atribuido foi:bbbbbb ", bbbbbb);
        } else if (aaaaaa.length > 0) {
          var medias = aaaaaa;
          console.log("valor atribuido foi:aaaaaa ", aaaaaa);
        } else {
          var medias = null;
        } //----------------------------------------------------------

        /* O ARQUIVO DD ARRASTA E SOLTA NAO PRECISA PASSAR PELO extractFiles
         * o array já vem da forma adequada para ser enviado
        */


        console.log("depois do ternario", aaaaaa); //se mnsg = null
        //console.log(this.medias)

        console.log("medias", medias); //se mnsg = null

        /**
         * medias 
         * [{…}]
         * 0: {filename: 'fundoAzul.jpg', type: 'image/jpeg', data: '/9j/4AAQSkZJRgABAQAAAQABAAD/4gI'}
         * 
         *    ABERTO FICA:
         * 
         * 0:
         * data: "/9j/4AAQSkZJRgABAQAAAQABAAD/4gIoSUNDX1BST0ZJTEUAAQEAA..."
         * filename: "fundoAzul.jpg"
         * type: "image/jpeg"
         * [[Prototype]]: Object
         * length: 1
         * []: (...)
         * firstObject: (...)
         * hasArrayObservers: (...)
         * lastObject: (...)
         * [[Prototype]]: Array(0)
        */
        //---------------------------------------------------
        //const medias2 = window.binariosArq ? extractFiles(window.binariosArq) : null;
        //let media;
        //if(medias){
        //  media = medias;
        //}
        //
        //if(medias2){
        //  media = medias2;
        //}

        this.send('resetFile');

        _methods.default.post('send', {
          message: message,
          contact: this.contactId,
          medias: medias,
          count_msgs: this.current_conversation.length,
          reply: this.replyId,
          session: localStorage.getItem('__whats_session')
        }).then(res => {
          Ember.set(this, 'replyId', null);
          Ember.set(this, 'stateWasSend', false);
          const chats = this.chats;
          const index = chats.findIndex(c => c.cid == res.contactId);
          Ember.set(chats[index], 'attendant', res.attendant);
        }).catch(err => {
          console.log('Erro ao enviar mensagem => ' + err.responseJSON.message);
        }).then(() => {
          Ember.set(this, 'showLoading', false);
        });

        this.send('removeReply');
        (0, _jquery.default)("#audioMB").css("display", "block");
        (0, _jquery.default)("#textMB").css("display", "none");
        (0, _jquery.default)(".convHistory").stop().animate({
          scrollTop: 9999999999999999999999999999999
        });
      },
      prepareSendAudio: async function () {
        await recorder.stop();
        (0, _jquery.default)("#micMsgGravacao").html('<b>Concluída</b>');
        (0, _jquery.default)("#start").html('');
        (0, _jquery.default)("#cancelarGravar").css("display", "block");
        (0, _jquery.default)("#botaoConfirmarClick").css("display", "none");
        (0, _jquery.default)("#sendAudioMB").css("display", "inline-block");
        (0, _jquery.default)("#closeAudioMB").css("display", "none");
        (0, _jquery.default)("#cancelar").html(' <span onClick="resetMic()" style="cursor: pointer;"><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" width="35" height="35">		<path opacity=".6" fill="#E53935" d="M17.5.25C7.988.25.25 7.988.25 17.5S7.988 34.75 17.5 34.75s17.25-7.738 17.25-17.25S27.012.25 17.5.25zm6.483 22.436l-1.297 1.297-5.186-5.186-5.186 5.186-1.297-1.297 5.187-5.186-5.187-5.186 1.297-1.297 5.186 5.186 5.187-5.186 1.297 1.297-5.187 5.186 5.186 5.186z">		</path></svg ></span>');
        await sleep(500);
        this.send('prepSendAudio2');
      },
      prepSendAudio2: function () {
        Ember.set(this, 'media2', {});
        Ember.set(this, 'readers2', []);
        var file = dataURLtoFile(document.getElementsByClassName('custIdAudio')[0].value, 'audio.oga');
        var reader2 = new FileReader();

        reader2.onload = () => {
          var x = reader2.result;
          this.media2.data = x.replace("data:audio/ogg;base64,", "");
          this.media2.filename = file.name;
          this.media2.type = "audio/ogg";
        };

        const valids = ['image', 'text', 'application', 'video', 'audio', 'ogg'];

        if (valids.some(v => file.type.includes(v))) {
          reader2.readAsDataURL(file);
          this.readers2.push(this.media2);
        } else {
          alert("Formato de arquivo invalido");
          this.send('resetFile');
        }

        Ember.set(this, 'messageConversation', '');
        Ember.set(this, 'showLoading', true); //this.send('sendAudio', this.readers2);
      },
      sendAudio: function () {
        _methods.default.post('send-audio', {
          message: '',
          contact: this.contactId,
          medias: this.readers2,
          count_msgs: this.current_conversation.length,
          reply: this.replyId,
          session: localStorage.getItem('__whats_session')
        }).then(res => {
          Ember.set(this, 'replyId', null);
          Ember.set(this, 'stateWasSend', false);
          const chats = this.chats;
          const index = chats.findIndex(c => c.cid == res.contactId);
          Ember.set(chats[index], 'attendant', res.attendant);
        }).catch(err => {
          console.log('Erro ao enviar mensagem => ' + err.responseJSON.message);
        }).then(() => {
          Ember.set(this, 'showLoading', false);
        });

        this.send('removeReply');
        (0, _jquery.default)("#audioMB").css("display", "block");
        (0, _jquery.default)("#textMB").css("display", "none");
        (0, _jquery.default)(".convHistory").stop().animate({
          scrollTop: 9999999999999999999999999999999
        });
        Ember.set(this, 'media2', {});
        Ember.set(this, 'readers2', []);
      },
      doneChat: function () {
        if (window.confirm('Tem certeza que deseja finalizar essa conversa?')) {
          _methods.default.post('done-chat', {
            contact: this.contactId,
            session: localStorage.getItem('__whats_session')
          }).then(res => {
            /* FUNCIONA
            const current_cid = res.contactId;
              
                const chats = this.chats;
              const index = chats.findIndex(c=> c.cid == current_cid)
              let new_chats = copy(chats, true);
              new_chats.splice(index,1)
              set(this,'chats', new_chats);   
              */
            alert(res.message);
          }).catch(err => {
            console.log('Erro ao enviar mensagem => ' + err.responseJSON.message);
          }).then(() => {
            Ember.set(this, 'showLoading', false);
          });

          this.send('removeReply');
          (0, _jquery.default)("#audioMB").css("display", "block");
          (0, _jquery.default)("#textMB").css("display", "none");
          (0, _jquery.default)(".convHistory").stop().animate({
            scrollTop: 9999999999999999999999999999999
          });
        }
      },
      deleteMessage: function (id) {
        Ember.set(this, 'showLoading', true);

        _methods.default.post('delete-message', {
          message_id: id,
          contact: this.contactId,
          everyone: true,
          //parametrizar de acordo com o botão selecionado
          count_msgs: this.current_conversation.length
        }).then(res => {}).catch(err => {
          console.log('Erro ao deletar mensagem => ' + err.responseJSON.message);
        }).then(() => {
          Ember.set(this, 'showLoading', false);
        });
      },
      downlaodMedia: function (id) {
        Ember.set(this, 'showLoadingDownloadMedia', true);
        Ember.set(this, 'idMediaDownload', id);

        _methods.default.post('download-media', {
          message_id: id,
          contact: this.contactId,
          count_msgs: this.current_conversation.length
        }).then(res => {
          let actual = res.message;
          let current = this.current_conversation;
          const index_message = current.findIndex(m => m.mid == id);

          if (index_message !== -1) {
            Ember.set(current[index_message], 'media', actual.media);
            Ember.set(current[index_message], 'media_mime', actual.media_mime);
            Ember.set(current[index_message], 'media_length', actual.media_length);
          }
        }).catch(err => {
          console.log('Erro ao baixar media => ' + err.responseJSON.message);
        }).then(() => {
          Ember.set(this, 'idMediaDownload', null);
          Ember.set(this, 'showLoadingDownloadMedia', false);
        });
      },
      clientInfo: function () {
        Ember.set(this, 'showLoading', true);

        _methods.default.get('client-info').then(res => {
          Ember.set(this, 'profile', res.profile);
          Ember.set(this, 'contactsDB', res.contacts);
        }).catch(error => {
          console.log('Erro ao obter informações do client do WhatsApp => ' + error.responseJSON.message);
        }).then(() => {
          Ember.set(this, 'showLoading', false);
        });
      },
      deleteChat: function () {
        var id = this.contactId;
        console.log(id);

        if (window.confirm('Tem certeza que deseja apagar essa Conversa?')) {
          Ember.set(this, 'showLoading', true);

          _methods.default.remove('chats', id).then(res => {
            const chats = this.chats;
            const index = chats.findIndex(c => c.cid == id);
            let new_chats = Ember.copy(chats, true);
            new_chats.splice(index, 1);
            Ember.set(this, 'chats', new_chats);
          }).catch(error => {
            console.log('Erro ao deletar conversa => ' + error.responseJSON.message);
          }).then(() => {
            Ember.set(this, 'showLoading', false);
          });
        }
      },
      filterChats: function () {
        const filter = this.filter.toLowerCase();

        if (filter.length < 3) {
          alert('O filtro mínimo é de 3 caracteres.');
        } else {
          Ember.set(this, 'showLoadingChats', true);

          _methods.default.post('filter-chats', {
            filter: filter,
            accessAllCategorys: this.accessAllCategorys,
            categorys_ids: this.attendant_categorys_ids
          }).then(res => {
            if (res.count === 0) {
              alert('Essa consulta não gerou resultados');
            } else {
              Ember.set(this, 'filtered', true);
              Ember.set(this, 'countChats', res.count);
              Ember.set(this, 'chats', res.chats);
              Ember.set(this, 'filter', '');
              Ember.set(this, 'allChatLoaded', false);
              this.send('applyUnreadCount', res.chats.filter(c => c.unread_count > 0 || c.unread_count == -1).length);
            }
          }).catch(error => {
            console.log('Erro ao filtrar conversas => ' + error.responseJSON.message);
          }).then(() => {
            Ember.set(this, 'showLoadingChats', false);
          });
        }
      },
      foward: function (id) {
        const chats = Ember.copy(this.chats, true);
        Ember.set(this, 'mfowardid', id);
        Ember.set(this, 'forwardChats', chats);
        (0, _jquery.default)(".forwardMessage").animate({
          opacity: '1',
          right: '0px'
        }, 180);
        /* hide others */

        (0, _jquery.default)(".overlay").fadeIn(180);
        (0, _jquery.default)(".menuWrap").fadeOut(180);
        (0, _jquery.default)(".menu").animate({
          opacity: '0',
          left: '-320px'
        }, 180);
      },
      deleteNchats: function (count) {
        if (window.confirm('Tem certeza que deseja apagar as ' + count + ' conversas mais antigas?')) {
          Ember.set(this, 'showLoading', true);

          _methods.default.post('delete-n-chats', {
            count: count,
            deleteGroups: true
          }).then(res => {
            let chats = this.chats;
            let new_chats = Ember.copy(chats, true);
            let aux = 0;

            for (let i = chats.length - 1; aux < count; i--) {
              let chat = new_chats[i];
              if (res.deletes.some(c => c.id._serialized === chat.cid) && (chat.isGroup && deleteGroups || !chat.isGroup)) new_chats.splice(i, 1);
              aux++;
            }

            Ember.set(this, 'chats', new_chats);
            alert(res.message);
          }).catch(error => {
            console.log('Erro ao deletar conversas mais antigas => ' + error.responseJSON.message);
          }).then(() => {
            Ember.set(this, 'showLoading', false);
          });
        }
      },
      muteChat: function () {
        const mute = !this.isMuted;

        _methods.default.post('mute-chat', {
          contact: this.contactId,
          mute: mute
        }).then(res => {
          const chats = this.chats;
          const index = chats.findIndex(c => c.cid == this.contactId);
          Ember.set(chats[index], 'muted', mute);
          Ember.set(this, 'isMuted', mute);
        }).catch(error => {
          console.log('Erro ao mutar conversas => ' + error.responseJSON.message);
        });
      },
      changeCategory: function (id, name) {
        if (window.confirm('Tem certeza que deseja transferir essa conversa para o setor: ' + name + '?')) {
          _methods.default.post('update-category', {
            chat_id: this.contactId,
            attendant: null,
            category_id: id,
            category_name: name
          }).then(res => {
            let permited = false;
            let accessAllCategorys = false;
            let correctlyCategory = id != undefined && this.attendant_categorys_ids.includes(id.toString());
            accessAllCategorys = this.accessAllCategorys == 'true';
            permited = accessAllCategorys || correctlyCategory;

            if (permited == false) {
              const chats = this.chats;
              const index = chats.findIndex(c => c.cid == this.contactId);

              if (index != -1) {
                let new_chats = Ember.copy(chats, true);
                new_chats.splice(index, 1);
                Ember.set(this, 'chats', new_chats);

                if (this.activeChat == this.contactId) {
                  this.send('removeLoadConv');
                }
              }
            }

            alert(res.message);
          }).catch(error => {
            console.log('Falha ao apagar mensagem: ' + error.responseJSON.message);
          });
        }
      },
      unreadChat: function (value) {
        const unread = value;

        _methods.default.post('unread-chat', {
          contact: this.contactId,
          unread: unread,
          valueUnread: value
        }).then(res => {
          const chats = this.chats;
          const index = chats.findIndex(c => c.cid == this.contactId);
          Ember.set(chats[index], 'unread', unread);
          Ember.set(this, 'isUnread', unread);
        }).catch(error => {
          console.log('Erro ao marcar conversa como nao lida => ' + error.responseJSON.message);
        });
      },
      pinChat: function () {
        const pin = !this.isPinned;

        _methods.default.post('pin-chat', {
          contact: this.contactId,
          pin: pin
        }).then(res => {
          const chats = this.chats;
          const index = chats.findIndex(c => c.cid == this.contactId);
          Ember.set(chats[index], 'pinned', pin);
          Ember.set(this, 'isPinned', pin);
        }).catch(error => {
          console.log('Erro ao mutar conversas => ' + error.responseJSON.message);
        });
      },
      loadCategorys: function () {
        _methods.default.get('categorys').then(res => {
          Ember.set(this, 'categorys', res.categorys);
        }).catch(error => {
          console.log('Falha ao carregar categorias: ' + error.responseJSON.message);
        });
      },
      selectCategoryNM: function (value) {
        this.set('categoryNM', value);
      }
    }
  });

  _exports.default = _default;
});